import parseUnderscoreToCamelCase from './parseUnderscoreToCamelCase';

const checkObjectOrArray = (value) => {
	let returnedValue;
	if (typeof value === 'object' && value !== null) {
		if (Array.isArray(value)) {
			const tempArray = [];
			value.forEach((subValue) => {
				const tempValue = checkObjectOrArray(subValue);
				tempArray.push(tempValue || subValue);
			});
			return tempArray;
		}
		// eslint-disable-next-line no-use-before-define
		return parseUnderscoreObjectToCamelCase(value);
	}
	return returnedValue;
};

const parseUnderscoreObjectToCamelCase = (underscoreObject) => {
	const tempObject = {};
	Object.keys(underscoreObject).forEach((key) => {
		const currentValue = underscoreObject[key];
		if (key === '_id') {
			tempObject[key] = currentValue;
		} else {
			const tempValue = checkObjectOrArray(currentValue);
			tempObject[parseUnderscoreToCamelCase(key)] = tempValue || currentValue;
		}
	});
	return tempObject;
};

export default parseUnderscoreObjectToCamelCase;
