import React, { createContext, useContext, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';

const UseScreenContext = createContext();

export const UseScreenProvider = ({ children }) => {
	const isMobilePort = useMediaQuery({ maxWidth: 576 });
	const isMobile = useMediaQuery({ maxWidth: 768 });
	const isTablet = useMediaQuery({ maxWidth: 992 });
	const isComputer = useMediaQuery({ minWidth: 992 });
	const isLargeScreen = useMediaQuery({ minWidth: 1200 });
	const isWideScreen = useMediaQuery({ minWidth: 1920 });
	const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
	const isMobilePortraitView = useMemo(
		() => (isComputer ? false : isPortrait),
		[isComputer, isPortrait]
	);

	return (
		<UseScreenContext.Provider
			value={useMemo(
				() => ({
					isMobilePort,
					isMobile,
					isTablet,
					isComputer,
					isLargeScreen,
					isWideScreen,
					isMobilePortraitView,
				}),
				[
					isMobilePort,
					isMobile,
					isTablet,
					isComputer,
					isLargeScreen,
					isWideScreen,
					isMobilePortraitView,
				]
			)}
		>
			{children}
		</UseScreenContext.Provider>
	);
};

/**
 * @function useScreen
 * @returns {{
 * isMobilePort: boolean;
 * isMobile: boolean;
 * isTablet: boolean;
 * isComputer: boolean;
 * isLargeScreen: boolean;
 * isWideScreen: boolean;
 * isMobilePortraitView: boolean;
 * }}
 */

export const useScreen = () => useContext(UseScreenContext);
