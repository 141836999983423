/* eslint-disable */
import * as Sentry from '@sentry/react';
import React from 'react';
import { useLocation } from 'react-router-dom';

const DefaultFallback = () => (
	<div className='px-2'>
		<h1>Something went wrong.</h1>
		<h4 className='px-2'>
			Try to <a href={location.pathname}>reload the page</a> or go back to{' '}
			<a href="/">Home Page</a>
		</h4>
	</div>
);

class ErrorBoundaryClass extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
		this.scopeLevel = props.scopeLevel;
		this.authTokens = props.authTokens;
	}

	componentDidUpdate() {
		const { isUpdated, setIsUpdated } = this.props;
		if (isUpdated) {
			setIsUpdated(false);
			this.setState({ hasError: false });
		}
	}

	componentDidCatch(error, errorInfo) {
		if (process.env.NODE_ENV !== 'development') {
			Sentry.withScope((scope) => {
				scope.setExtras(errorInfo);
				if (this.authTokens?.user_name) {
					scope.setUser({
						username: this.authTokens.user_name,
					});
					scope.setTag('loggedIn', true);
				}
				scope.setLevel(this.scopeLevel);
				console.log(Sentry.captureException(error));
			});
		} else {
			console.error(error);
		}
		this.setState({ hasError: true });
	}

	render() {
		const { state, props } = this;
		const { hasError } = state;
		const { fallback: Fallback = DefaultFallback, children } = props;
		if (hasError) {
			return <Fallback />;
		}
		return children;
	}
}

/**
 * @function ErrorBoundary
 * @param {{
 * isUpdated:? boolean;
 * setIsUpdated:? Function;
 * fallback:? JSX.Element;
 * scopeLevel:? "fatal" | "error" | "warning" | "log" | "info" | "debug";
 * authTokens:?;
 * children: Node;
 * }} props
 * @description Wrap any component with an ErrorBoundary to catch error and display a fallback
 * @param **isUpdated** - Optional, if you want to wrap a list of components with conditional render (for example - a list of components that will render just one component based on query name), this will ensure the ErrorBoundary reset for every new component.
 * @param **setIsUpdated** - Optional (required if using isUpdated so state will reset back).
 * @param **fallback** - Optional, instead of default fallback.
 * @param **scopeLevel** - Optional, default to "error".
 * @param **authTokens** - Optional, to set context if a user is logged in.
 * @example <caption>Default Fallback</caption>
 * <h1>Something went wrong.</h1>
 */

const ErrorBoundary = ({
	isUpdated,
	setIsUpdated,
	fallback,
	scopeLevel = 'error',
	authTokens = null,
	children,
}) => (
	<ErrorBoundaryClass
		isUpdated={isUpdated}
		setIsUpdated={setIsUpdated}
		fallback={fallback}
		scopeLevel={scopeLevel}
		authTokens={authTokens}
	>
		{children}
	</ErrorBoundaryClass>
);

export default ErrorBoundary;
