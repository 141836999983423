import React, { createContext, useContext } from 'react';

export const UserSettingsContext = createContext();

export function useUserSettings() {
	return useContext(UserSettingsContext);
}

export const UserSettingsProvider = (props) => {
	const { value, children } = props;
	return (
		<UserSettingsContext.Provider value={value}>
			{children}
		</UserSettingsContext.Provider>
	);
};
