import React, {
	createContext,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';

export const PrerenderContext = createContext();

export function usePrerender() {
	return useContext(PrerenderContext);
}

export const PrerenderProvider = (props) => {
	const { children } = props;
	const [prerenderReady, setPrerenderReady] = useState(false);
	const value = useMemo(
		() => ({ prerenderReady, setPrerenderReady }),
		[prerenderReady]
	);
	useEffect(() => {
		window.prerenderReady = prerenderReady;
	}, [prerenderReady]);
	return (
		<PrerenderContext.Provider value={value}>
			{children}
		</PrerenderContext.Provider>
	);
};
