import { handleSetReducerState } from './dynamicStateReducer';

// Initial State

const initialState = {
	suggestedSearchBoxOptions: [],
	userSearchBoxDefaultOptions: [],
	stockSearchBoxDefaultOptions: [],
	multiSearchBoxDefaultOptions: [],
	financialKeysTooltips: {},
	leaderboardList: [],
};

/**
 * @function handleSetSearchBoxOptions
 * @description Change the states of searchBoxOptions.
 * @param {function} dispatch - **--IGNORE IF CALLED IN A THUNK REDUCER, ELSE USE THE THUNK DISPATCH FROM THE THUNK REDUCER HOOK--** the dispatch function to be called.
 * @param {object} searchBoxOptions - searchBoxOptions.
 */

const handleSetSearchBoxOptions = (dispatch, suggestedSearchBoxOptions) => {
	handleSetReducerState(dispatch, { suggestedSearchBoxOptions });
};

/**
 * @function handleSetFinancialKeysTooltips
 * @description Change the states of financialKeysTooltips.
 * @param {function} dispatch - **--IGNORE IF CALLED IN A THUNK REDUCER, ELSE USE THE THUNK DISPATCH FROM THE THUNK REDUCER HOOK--** the dispatch function to be called.
 * @param {Array<object>} financialKeysTooltips - financialKeysTooltips.
 */

const handleSetFinancialKeysTooltips = (dispatch, financialKeysTooltips) => {
	handleSetReducerState(dispatch, { financialKeysTooltips });
};

/**
 * @function handleResetState
 * @description Rest to initial state.
 * @param {function} dispatch - **--IGNORE IF CALLED IN A THUNK REDUCER, ELSE USE THE THUNK DISPATCH FROM THE THUNK REDUCER HOOK--** the dispatch function to be called.
 */

const handleResetState = (dispatch) => {
	handleSetReducerState(dispatch, initialState);
};

export {
	initialState,
	handleSetSearchBoxOptions,
	handleSetFinancialKeysTooltips,
	handleResetState,
};
