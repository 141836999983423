import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import jikaLogo from '../../../images/guest-placeholder.svg';
import { sendClickEvent } from '../../../utils/eventsService';

/**
 * @function UserAvatar
 * @param {{
 * id: string;
 * className?: string?;
 * userAvatarUrl?: string;
 * size?: number;
 * noNavigate?: boolean;
 * }} props
 * @returns
 */

export default function UserAvatar(props) {
	const {
		id,
		className = '',
		userName = null,
		userAvatarUrl = null,
		size = 40,
		noNavigate = false,
		onClick,
		eventLocation,
		isButton = true,
	} = props;

	const navigate = useNavigate();

	const [isError, setIsError] = useState();

	const avatarUrl = useMemo(() => {
		if (isError)
			return `https://api.dicebear.com/7.x/initials/svg?seed=${userName}`;
		if (userAvatarUrl) return userAvatarUrl;
		if (userName)
			return `https://api.dicebear.com/7.x/initials/svg?seed=${userName}`;
		return jikaLogo;
	}, [userAvatarUrl, userName, isError]);

	const handleAvatarClick = useCallback(() => {
		if (onClick) {
			onClick();
		} else if (userName) {
			if (!noNavigate) {
				sendClickEvent({
					element: 'user_icon',
					user_name: userName,
					event_location: eventLocation,
				});
				navigate(`/u/${userName}`);
			}
		}
	}, [onClick, userName, noNavigate, eventLocation, navigate]);

	const Container = useCallback(
		(avatarProps) => {
			const { children: avatarChildren } = avatarProps;
			if (isButton) {
				return (
					<div
						className="d-flex align-items-start"
						onClick={handleAvatarClick}
						role="button"
						tabIndex={0}
					>
						{avatarChildren}
					</div>
				);
			}
			return (
				<div
					className="d-flex align-items-center"
					style={{ pointerEvents: 'none' }}
				>
					{avatarChildren}
				</div>
			);
		},
		[isButton, handleAvatarClick]
	);

	return (
		<Container>
			<img
				id={`track-element${id ? ` ${id}` : ''}`}
				className={className}
				height={size}
				width={size}
				src={avatarUrl}
				onError={setIsError}
				style={{ borderRadius: '50%', objectFit: 'cover' }}
				alt="user-avatar"
			/>
		</Container>
	);
}
