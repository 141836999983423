import { isFunction } from 'lodash';

/**
 * @module dynamicStateReducer
 */

const reducerActions = {
	SET_NEW_STATE: 'SET_NEW_STATE',
};

/**
 * @function dynamicStateReducer
 * @description Create a reducer with a dynamic state.
 * @example <caption>Example reducer usage</caption>
 * // yourReducer.jsx
 * import { reducerActions } from "./dynamicStateReducer";
 * // Initial State
 * const initialState = {
 * searchBoxOptions: [],
 * financialKeysTooltips: {},
 * };
 * // Example reducer setAll function
 * const handleSetAll = (dispatch, all) => {
 * dispatch({
 * type: reducerActions.SET_NEW_STATE,
 * payload: all,
 * });
 * };
 * export {
 * initialState,
 * handleSetAll,
 * };
 * // yourComponent.jsx
 * import React, { useCallback, useMemo, useState } from 'react';
 * import useThunkReducer from '../context/useThunkReducer';
 * import { dynamicStateReducer } from '../context/dynamicStateReducer';
 * import { handleSetAll, initialState } from '../context/indexReducer';
 * // Create your reducer
 * const [state, thunkDispatch] = useThunkReducer(dynamicStateReducer, initialState);
 * const { searchBoxOptions, financialKeysTooltips } = useMemo(() => state, [state]);
 * ...
 * // Example component setState functions
 * const setAllIndexReducer = useCallback((newState) => {
 * handleSetAll(thunkDispatch, newState);
 * }, [thunkDispatch]);
 * // Example usage of setAll function
 * useEffect(() => {
 * const promie = async () => axios.DoSomething().then((res) => res);
 * ...
 * Promise.all([promie(), promie2(), promie3()]).then((res) => {
 * const newState = {
 * 	searchBoxOptions: res[0],
 * 	financialKeysTooltips: res[1],
 * };
 * setAllIndexReducer(newState);
 * });
 * }, []);
 */

const dynamicStateReducer = (state, action) => {
	const { SET_NEW_STATE } = reducerActions;
	const { type = '', payload = {} } = action;
	if (type === SET_NEW_STATE) {
		const newValues = {};
		const keys = Object.keys(payload);
		const values = Object.values(payload);
		keys.forEach((key, index) => {
			if (isFunction(values[index])) {
				newValues[key] = values[index](state[key]);
			} else {
				newValues[key] = values[index];
			}
		});
		return {
			...state,
			...newValues,
		};
	}
	return state;
};

/**
 * @function handleSetReducerState
 * @param {function} dispatch - **--IGNORE IF CALLED IN A THUNK DISPATCH, ELSE USE THE THUNK DISPATCH FROM THE THUNK REDUCER HOOK--** the dispatch function to be called.
 * @param {object} newState - **State value can be a function that recieve the current state as the first argument!** Object with fields corresponding to the current state fields. **--If the fields dont exist they will be created!--**.
 * @example <caption>Setting new state</caption>
 * const handleSetConfig = (dispatch, config) => {
 * handleSetReducerState(dispatch, {
 * config: (currentState) =>
 * currentState.map((item) =>
 * item.name === config.name
 * ? {
 * ...item,
 * value: config.value,
 * }
 * : item
 * ),
 * });
 * };
 */

const handleSetReducerState = (dispatch, newState) => {
	dispatch({
		type: reducerActions.SET_NEW_STATE,
		payload: newState,
	});
};

export { reducerActions, dynamicStateReducer, handleSetReducerState };
