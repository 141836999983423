import React, { createContext, useContext } from 'react';

export const EmbedContext = createContext();

/**
 * @function useEmbed
 * @returns {{
 * isEmbed: boolean;
 * setIsEmbed: Function;
 * isEmbedLoading: boolean;
 * setIsEmbedLoading: Function;
 * }}
 */

export function useEmbed() {
	return useContext(EmbedContext);
}

export const EmbedProvider = (props) => {
	const { value, children } = props;
	return (
		<EmbedContext.Provider value={value}>{children}</EmbedContext.Provider>
	);
};
