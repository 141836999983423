import parseFirstCharToUpperCase from './parseFirstCharToUpperCase';

const parseUnderscoreToCamelCase = (underscroredString) =>
	underscroredString
		.split('_')
		.map((item, index) =>
			index === 0 ? item : parseFirstCharToUpperCase(item)
		)
		.join('');

export default parseUnderscoreToCamelCase;
