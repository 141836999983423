import { useCallback, useReducer } from 'react';
import isFunction from 'lodash/isFunction';
// eslint-disable-next-line no-unused-vars
import { dynamicStateReducer } from '../reducers/dynamicStateReducer';

/**
 * @function useThunkReducer
 * @description **--Please use only with the {@link dynamicStateReducer}! The dispatch will be used as the first arguemnt for the function (if a function was passed to the thunk dispatch), use compatible functions only!--**, This hook allow you to pass either an action string or a function to dispatch.
 * @example <caption>Example usage with argless function (only dispatch required)</caption>
 * const reducerFunction = (dispatch) => {
 *   dispatch({ type });
 * }
 *
 * const wrapperFunction = () => {
 *   thunkDispatch(reducerFunction);
 * }
 * @example <caption>Example usage with argfull function (more then just dispatch required)</caption>
 * const reducerFunction = (dispatch, ...args) => {
 *   dispatch({ type, payload });
 * }
 *
 * const wrapperFunction = (...args) => {
 *   reducerFunction(thunkDispatch, ...args);
 * }
 * @example <caption>Example usage 2 with argfull function (more then just dispatch required)</caption>
 * const reducerFunction = (dispatch, ...args) => {
 *   dispatch({ type, payload });
 * }
 *
 * const wrapperFunction = (...args) => {
 *   thunkDispatch(reducerFunction, ...args);
 * }
 *
 * @param {function} reducer - The reducer to be used.
 * @param {object} initState - Object with the initial state for the reducer.
 * @returns {[object, function]} An array with a state object and the thunkDispatch function.
 */

const useThunkReducer = (reducer, initState) => {
	const [state, dispatch] = useReducer(reducer, initState);

	const thunkDispatch = useCallback((action, ...args) => {
		if (isFunction(action)) {
			action(dispatch, ...args);
		} else {
			dispatch(action);
		}
	}, []);

	return [state, thunkDispatch];
};

export default useThunkReducer;
