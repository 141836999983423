import { setPWAModalExecuted } from '../components/common/PWA/utils';

const eventTarget = new EventTarget();

const deferredPrompt = {
	event: null,
};

const subscribe = (callback) => {
	if (deferredPrompt.event) {
		callback(deferredPrompt);
		return () => {};
	}

	const handler = () => {
		if (deferredPrompt.event) {
			callback(deferredPrompt);
			eventTarget.removeEventListener('ready', handler);
		}
	};
	eventTarget.addEventListener('ready', handler);

	return () => {
		eventTarget.removeEventListener('ready', handler);
	};
};

deferredPrompt.subscribe = subscribe;

window.addEventListener('beforeinstallprompt', (e) => {
	e.preventDefault();
	deferredPrompt.event = e;
	deferredPrompt.event.prompt = () => {
		setPWAModalExecuted();
		deferredPrompt.event.prompt();
	};
	eventTarget.dispatchEvent(new Event('ready'));
});

export default deferredPrompt;
