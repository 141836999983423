import React, {
	useReducer,
	useEffect,
	createContext,
	useContext,
	useMemo,
	useState,
} from 'react';

// Define types for actions
const SET_VERSION = 'SET_VERSION';
const INITIALIZE_VERSIONS = 'INITIALIZE_VERSIONS';

// Reducer function for AB testing versions
const abTestingVariantReducer = (state, action) => {
	switch (action.type) {
		case SET_VERSION: {
			// Enclosing the case in a block to create a new scope
			const newState = { ...state, [action.key]: action.value };
			localStorage.setItem('abVariants', JSON.stringify(newState));
			return newState;
		}
		case INITIALIZE_VERSIONS: {
			// Enclosing the case in a block to create a new scope
			localStorage.setItem('abVariants', JSON.stringify(action.data));
			return action.data;
		}
		default:
			return state;
	}
};

// Context for the AB versions state
const ABTestingContext = createContext();

// Provider component for AB testing versions
export const ABTestingProvider = ({ children }) => {
	const [versions, dispatch] = useReducer(abTestingVariantReducer, {});
	const [isLoading, setLoading] = useState(true);

	// Effect to initialize the versions from local storage
	useEffect(() => {
		const storedVariants = JSON.parse(localStorage.getItem('abVariants')) || {};
		dispatch({ type: INITIALIZE_VERSIONS, data: storedVariants });
		setLoading(false);
	}, []);

	const providerValue = useMemo(
		() => ({ versions, dispatch, isLoading }),
		[versions, dispatch, isLoading]
	);

	return (
		<ABTestingContext.Provider value={providerValue}>
			{children}
		</ABTestingContext.Provider>
	);
};

// Custom hooks for setting and getting AB testing versions
export const useABTesting = () => {
	const { versions, dispatch, isLoading } = useContext(ABTestingContext);
	const setVariant = (key, value) => {
		dispatch({ type: SET_VERSION, key, value });
	};
	const getVariant = (key, options = ['A', 'B']) => {
		if (isLoading) {
			return 'loading';
		}
		if (versions[key] && options.includes(versions[key])) {
			return versions[key];
		}
		const randomIndex = Math.floor(Math.random() * options.length);
		setVariant(key, options[randomIndex]);
		return options[randomIndex];
	};
	return { setVariant, getVariant };
};
