import React, { createContext, useContext } from 'react';

export const TrendsContext = createContext();

export function useTrends() {
	return useContext(TrendsContext);
}

export const TrendsProvider = (props) => {
	const { value, children } = props;

	return (
		<TrendsContext.Provider value={value}>{children}</TrendsContext.Provider>
	);
};
