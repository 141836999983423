import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useToast } from '../../../context/Toast';
import AchievementToast from '../toasts/AchievementToast';
import { useAuth } from '../../../context/Auth';
import AchievementsAcquired from './AchievementsAcquired';
import { sendEvent } from '../../../utils/eventsService';

export default function AchievementsUpdates({ achievements }) {
	const { addToast } = useToast();
	const { getAuthHeader } = useAuth();
	const [initialAchievements, setInitialAchievements] = useState();
	const [achievementsState, setAchievementsState] = useState();
	useEffect(() => {
		if (achievements) {
			if (!initialAchievements) {
				const newState = achievements.filter(
					(achievement) => achievement.user_data?.status === 'done'
				);
				setInitialAchievements(newState);
				setAchievementsState(newState);
				if (newState.length > 0) {
					newState.forEach((newAchievement) => {
						sendEvent({
							gaName: 'achievement_acquired',
							gaParams: {
								achievement: newAchievement.name,
								stage: newAchievement.stage,
							},
						});
					});
					axios.post(
						'/api/achievements/seen',
						{},
						{ headers: getAuthHeader() }
					);
				}
			} else if (achievements.length > 0) {
				const currentAchievementsNames = achievementsState.map(
					(achievement) => achievement.name
				);
				const newAchievements = achievements.filter(
					(achievement) =>
						achievement.user_data?.status === 'done' &&
						!currentAchievementsNames.includes(achievement.name)
				);
				newAchievements.forEach((achievement) => {
					if (!achievement.no_toast) {
						addToast(<AchievementToast achievement={achievement} />);
					}
				});
				setAchievementsState([...achievementsState, ...newAchievements]);
				if (newAchievements.length > 0) {
					newAchievements.forEach((newAchievement) => {
						sendEvent({
							gaName: 'achievement_acquired',
							gaParams: {
								achievement: newAchievement.name,
								stage: newAchievement.stage,
							},
						});
					});
					axios.post(
						'/api/achievements/seen',
						{},
						{ headers: getAuthHeader() }
					);
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [achievements]);
	if (!initialAchievements?.length > 0) {
		return null;
	}
	return (
		<AchievementsAcquired
			achievements={initialAchievements}
			isModal
			onClose={() => setInitialAchievements([])}
		/>
	);
}
