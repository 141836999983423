import React, { createContext, useContext } from 'react';

export const TourContext = createContext();

export function useTour() {
	return useContext(TourContext);
}

export const TourProvider = (props) => {
	const { value, children } = props;
	return <TourContext.Provider value={value}>{children}</TourContext.Provider>;
};
