import React, { useEffect, useMemo, useState } from 'react';

export default function LogoRender({
	src = '',
	className = '',
	size = '',
	alt = '',
	style = {},
}) {
	const [logoSrc, setLogoSrc] = useState(src);

	const sizePx = useMemo(() => {
		const tempSize = `${size}`.toLowerCase();
		if (
			tempSize.includes('px') ||
			tempSize.includes('em') ||
			tempSize.includes('rem') ||
			tempSize.includes('vh') ||
			tempSize.includes('vw') ||
			tempSize.includes('%')
		) {
			return tempSize;
		}
		return `${tempSize}px`;
	}, [size]);

	useEffect(() => setLogoSrc(src), [src]);

	const notFound = useMemo(
		() => (
			<div
				style={
					sizePx
						? { height: sizePx, minWidth: sizePx, maxWidth: sizePx, ...style }
						: style
				}
				className={`logo-container ${className}`}
			/>
		),
		[sizePx, style, className]
	);

	const logo = useMemo(
		() => (
			<div
				style={
					sizePx
						? { height: sizePx, minWidth: sizePx, maxWidth: sizePx, ...style }
						: style
				}
				className={`logo-container ${className}`}
			>
				{logoSrc && logoSrc !== '' && (
					<img
						src={logoSrc}
						alt={alt}
						onError={() => setLogoSrc(null)}
						height={sizePx}
						width={sizePx}
					/>
				)}
			</div>
		),
		[alt, className, logoSrc, style, sizePx]
	);

	return logoSrc ? logo : notFound;
}
