import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import MobileModal from '../components/common/modals/MobileModal';

export default function EnableNotificationsModal({
	isShow: isShowProp,
	onChange,
	enableNotifications,
}) {
	const [isShow, setIsShow] = useState(false);

	const handleEnableNotifications = useCallback(() => {
		enableNotifications();
	}, [enableNotifications]);

	useEffect(() => {
		if (typeof onChange === 'function') {
			onChange(isShow);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isShow]);

	useEffect(() => {
		setIsShow(isShowProp);
	}, [isShowProp]);

	return (
		<MobileModal isShow={isShow} onChange={setIsShow}>
			<div className="px-2 py-3 d-flex flex-column align-items-center text-left">
				<h2 className="w-100 mb-2">Never Miss a Lesson! 📘</h2>
				<p className="w-100 mb-3 text-black">
					Enable notifications to stay on top of our daily lessons, featuring
					in-depth looks at trending companies and investment topics.
				</p>

				<div className="d-flex flex-wrap-reverse">
					<Button
						className="mx-auto text-nowrap"
						variant="none"
						onClick={() => {
							setIsShow(false);
						}}
					>
						Not now
					</Button>
					<Button
						className="mx-auto text-nowrap"
						onClick={handleEnableNotifications}
					>
						Enable Notifications
					</Button>
				</div>
			</div>
		</MobileModal>
	);
}
