import axios from 'axios';
import React, {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
// import { useNavigate } from 'react-router-dom';
// import useLocalStorage from 'use-local-storage';
import { useAuth } from './Auth';
// import { sendEvent } from '../utils/eventsService';
// import { useAuthQueue } from './AuthQueue';

export const AcademyContext = createContext();

/**
 * @function useAcademy
 * @returns {{
 * isLoading: boolean;
 * skills: [];
 * setSkills: (skills: []) => void;
 * setSkill: (skillName: string, skillUpdate: {}) => void;
 * setSkillItem: (skillName: string, itemName: string, itemUpdate: {}) => void;
 * userTitles: {};
 * generalUserData?: {};
 * setGeneralUserData: (setGeneralUserData: {}) => void;
 * skillFinishModal: string;
 * setSkillFinishModal: (skillFinishModal: string) => void;
 * programPurchaseModal: [{}];
 * setProgramPurchaseModal: (programPurchaseModal: [{}]) => void;
 * skillPurchaseModal:? {};
 * setSkillPurchaseModal: (skillPurchaseModal:? {}) => void;
 * showContinueModal: boolean;
 * setShowContinueModal: (showContinueModal: boolean) => void;
 * isAcademyEnrolled: boolean;
 * setIsAcademyEnrolled: (isAcademyEnrolled: boolean) => void;
 * academySale: boolean;
 * setAcademySale: (academySale: boolean) => void;
 * filterEmptyItems: (items: []) => [];
 * nextSkill,
 * nextLesson,
 * nextItem,
 * calculatePrice: (price: number) => number;
 * dataRecievedAt: {current: number};
 * }}
 */

export function useAcademy() {
	return useContext(AcademyContext);
}

const fetchAcademySkills = async (headers) =>
	axios.get('/api/academy/skills', { headers }).then((res) => res.data);

const fetchAcademyLessons = async (courseName, headers) =>
	axios
		.get(`/api/academy/course/${courseName}/lessons`, { headers })
		.then((res) => res.data);

export const AcademyProvider = ({ children }) => {
	const { authTokens, getAuthHeader } = useAuth();

	const [isLoading, setIsLoading] = useState(true);
	const [skills, setSkills] = useState([]);

	const resetAcademyData = useCallback(() => {
		setSkills([]);
	}, []);

	const getActiveSkill = useCallback(() => {
		if (skills.length === 0) {
			return null;
		}
		if (!skills[0].user_data?.progress) {
			return skills[0];
		}
		const activeSkills = skills.filter(
			(skill) => skill.user_data?.progress && skill.user_data.progress < 100
		);
		if (activeSkills.length === 0) {
			return skills[skills.length - 1];
		}
		return activeSkills[0];
	}, [skills]);

	const getActiveCourse = useCallback((skill) => {
		if (!skill) {
			return null;
		}
		let foundCourse = false;
		let activeCourse = null;
		skill.unit_items.some((unit) => {
			unit.course_items.some((currentCourse) => {
				activeCourse = {
					...currentCourse,
					skill: skill.title,
					skillName: skill.name,
					unit: unit.title,
					unitName: unit.name,
				};
				if (activeCourse.user_data?.progress !== 100) {
					foundCourse = true;
				}
				return foundCourse;
			});
			return foundCourse;
		});
		return activeCourse;
	}, []);

	const getAcademyCourse = useCallback(
		async (skillName, unitName, courseName) => {
			if (skills.length === 0) {
				return Promise.resolve();
			}
			const currentCourse = skills
				.find((skill) => skill.name === skillName)
				.unit_items.find((unit) => unit.name === unitName)
				.course_items.find((course) => course.name === courseName);
			// If the first lesson in course doesn't have any item, it means that all the lessons probably doesn't have
			// and that's why we can rely on the first items to check whether we need to load the items or not.
			const lessonItems = currentCourse.lesson_items[0].item_items;
			if (lessonItems?.length > 0) {
				return Promise.resolve(currentCourse);
			}
			return fetchAcademyLessons(courseName, getAuthHeader()).then((res) => {
				setSkills((currentState) =>
					currentState.map((skill) =>
						skill.name === skillName
							? {
									...skill,
									unit_items: skill.unit_items.map((unit) =>
										unit.name === unitName
											? {
													...unit,
													course_items: unit.course_items.map((course) =>
														course.name === courseName
															? {
																	...course,
																	lesson_items: res.lessons,
															  }
															: course
													),
											  }
											: unit
									),
							  }
							: skill
					)
				);
				currentCourse.lesson_items = res.lessons;
				return currentCourse;
			});
		},
		[skills, getAuthHeader]
	);

	const setAcademySkill = useCallback((skill, skillData) => {
		setSkills((currentState) =>
			currentState.map((item) => (item.name === skill ? skillData : item))
		);
	}, []);

	useEffect(() => {
		if (authTokens?.user_name) {
			fetchAcademySkills(getAuthHeader())
				.then((res) => {
					setSkills(res.skills);
				})
				.finally(() => {
					setIsLoading(false);
				});
		} else {
			resetAcademyData();
			setIsLoading(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authTokens?.user_name]);
	const value = useMemo(
		() => ({
			skills,
			isLoading,
			getAcademyCourse,
			getActiveSkill,
			getActiveCourse,
			setAcademySkill,
		}),
		[
			skills,
			isLoading,
			getAcademyCourse,
			getActiveSkill,
			getActiveCourse,
			setAcademySkill,
		]
	);
	return (
		<AcademyContext.Provider value={value}>{children}</AcademyContext.Provider>
	);
};

// eslint-disable-next-line no-unused-vars
const AcademyProviderOLD = (props) => {
	const { children } = props;
	const { authTokens, getAuthHeader } = useAuth();
	const dataRecievedAt = useRef();
	const [isLoading, setIsLoading] = useState(true);
	const [skills, setSkills] = useState([]);
	const programSkills = useMemo(() => {
		const dateKey = Date.now();
		return skills
			.filter((skill) => skill.user_program)
			.map((skill) => ({ ...skill, key: `${skill.name}-${dateKey}` }));
	}, [skills]);
	const [userTitles, setUserTitles] = useState({});
	const [generalUserData, setGeneralUserData] = useState();
	const [isAcademyEnrolled, setIsAcademyEnrolled] = useState(false);
	const [academySale, setAcademySale] = useState(false);

	const [skillFinishModal, setSkillFinishModal] = useState('');
	const [programQuestionnaire, setProgramQuestionnaire] = useState(false);
	const [programPurchaseModal, setProgramPurchaseModal] = useState([]);
	const [skillPurchaseModal, setSkillPurchaseModal] = useState('');
	const [showContinueModal, setShowContinueModal] = useState(false);

	const setAcademyData = useCallback(async () => {
		setIsLoading(true);
		return fetchAcademySkills(getAuthHeader()).then((data) => {
			setSkills(data.academy_skills);
			setUserTitles(data.user_titles);
			setGeneralUserData(data.general_user_data);
			setIsAcademyEnrolled(data.is_academy_enrolled);
			setAcademySale(data.academy_sale);
			setIsLoading(false);
		});
	}, [getAuthHeader]);

	const resetAcademyData = useCallback(() => {
		setSkills([]);
		setUserTitles({});
		setGeneralUserData(null);
		setIsAcademyEnrolled(false);
		setAcademySale(false);
	}, []);

	const filterEmptyItems = useCallback(
		(lessons) =>
			lessons
				.map((lesson) => ({
					...lesson,
					items: lesson.items.filter(
						(item) =>
							(item.academy_type === 'video' && item.src) ||
							(item.academy_type === 'quiz' && item.questions.length > 0)
					),
				}))
				.filter((lesson) => lesson.items.length > 0),
		[]
	);
	const calculatePrice = useCallback(
		(price) => (academySale ? Number((price * 0.4).toFixed(2)) : price),
		[academySale]
	);
	const handleSetGeneralUserData = useCallback((value) => {
		setGeneralUserData((currentState) => ({
			...(currentState || {}),
			...(value || {}),
		}));
	}, []);
	const setSkill = useCallback((skillName, skillUpdate) => {
		setSkills((currentState) =>
			currentState.map((v) => {
				if (v.name === skillName) {
					const newV = { ...v };
					Object.entries(skillUpdate).forEach(([sk, sv]) => {
						let nsv = sv;
						if (typeof nsv === 'function') {
							nsv = nsv(v[sk]);
						}
						newV[sk] = nsv;
					});
					return newV;
				}
				return v;
			})
		);
	}, []);
	const setSkillItem = useCallback(
		(skillName, itemName, itemUpdate) => {
			setSkill(skillName, {
				lessons: (currentLessons) =>
					currentLessons.map((currentLesson) => ({
						...currentLesson,
						items: currentLesson.items.map((vi) => {
							if (vi.name === itemName) {
								const newV = { ...vi };
								Object.entries(itemUpdate).forEach(([ik, iv]) => {
									let niv = iv;
									if (typeof niv === 'function') {
										niv = niv(vi[ik]);
									}
									newV[ik] = niv;
								});
								return newV;
							}
							return vi;
						}),
					})),
			});
		},
		[setSkill]
	);

	const filteredSkills = useMemo(
		() => skills.filter((item) => Object.keys(item).includes('subtitle')),
		[skills]
	);
	const nextSkill = useMemo(
		() => filteredSkills.find((skill) => (skill.progress || 0) < 100),
		[filteredSkills]
	);
	const nextLesson = useMemo(
		() =>
			filterEmptyItems(nextSkill?.lessons || []).find((lesson) =>
				lesson.items.some((item) => !item.done)
			),
		[nextSkill, filterEmptyItems]
	);
	const nextItem = useMemo(
		() => nextLesson?.items.find((item) => !item.done),
		[nextLesson]
	);

	const value = useMemo(
		() => ({
			skills,
			setSkills,
			setSkill,
			setSkillItem,
			programSkills,
			userTitles,
			generalUserData,
			setGeneralUserData: handleSetGeneralUserData,
			skillFinishModal,
			setSkillFinishModal,
			programPurchaseModal,
			setProgramPurchaseModal,
			skillPurchaseModal,
			setSkillPurchaseModal,
			showContinueModal,
			setShowContinueModal,
			isAcademyEnrolled,
			setIsAcademyEnrolled,
			academySale,
			setAcademySale,
			filterEmptyItems,
			calculatePrice,
			isLoading,
			dataRecievedAt,
			filteredSkills,
			nextSkill,
			nextLesson,
			nextItem,
			programQuestionnaire,
			setProgramQuestionnaire,
			setAcademyData,
			resetAcademyData,
		}),
		[
			skills,
			setSkill,
			setSkillItem,
			programSkills,
			userTitles,
			generalUserData,
			handleSetGeneralUserData,
			skillFinishModal,
			programPurchaseModal,
			skillPurchaseModal,
			showContinueModal,
			isAcademyEnrolled,
			academySale,
			filterEmptyItems,
			calculatePrice,
			isLoading,
			filteredSkills,
			nextSkill,
			nextLesson,
			nextItem,
			programQuestionnaire,
			setProgramQuestionnaire,
			setAcademyData,
			resetAcademyData,
		]
	);

	useEffect(() => {
		if (authTokens?.user_name) {
			setAcademyData()
				.then(() => {
					dataRecievedAt.current = Date.now();
				})
				.finally(() => {
					setIsLoading(false);
				});
		} else {
			resetAcademyData();
			setIsLoading(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authTokens?.user_name]);

	return (
		<AcademyContext.Provider value={value}>{children}</AcademyContext.Provider>
	);
};
