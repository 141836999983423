import { useEffect, useRef } from 'react';
import { sendEvent } from '../utils/eventsService';

const useViewEvent = (shouldSend, gaName, gaParams) => {
	const sendEventRef = useRef();
	useEffect(() => {
		if (shouldSend && !sendEventRef.current) {
			sendEvent({ gaName, gaParams });
			sendEventRef.current = true;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [shouldSend, shouldSend]);
};

export default useViewEvent;
