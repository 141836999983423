import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useCalendlyEventListener, InlineWidget } from 'react-calendly';
import { sendClickEvent } from '../../utils/eventsService';
import InfoModal from './modals/InfoModal';
import useViewEvent from '../../hooks/useViewEvent';

/**
 *
 * @param {{
 * btnId: string;
 * btnClassName?: string;
 * textClassName?; string;
 * text: string;
 * }} props
 * @returns
 */

export default function CalendlyButton(props) {
	const [showModal, setShowModal] = useState(false);
	const {
		variant,
		btnId,
		btnClassName,
		eventLocation,
		onEventScheduled,
		children,
		variantKey,
	} = props;

	useCalendlyEventListener({
		onEventScheduled,
	});

	useViewEvent(showModal, `view-${eventLocation}-schedule-modal`, {
		variant: variantKey,
	});

	return (
		<>
			<Button
				variant={variant}
				className={btnClassName}
				id={btnId}
				onClick={() => {
					sendClickEvent({
						element: `${eventLocation}_interview`,
						variant: variantKey,
					});
					setShowModal(true);
				}}
			>
				{children}
			</Button>
			<InfoModal
				isShow={showModal}
				onChange={setShowModal}
				dialogClassName="sign-modal signup-modal"
			>
				<InlineWidget url="https://calendly.com/shabtai/20-minute-meeting" />
			</InfoModal>
		</>
	);
}
