import checkServiceWorker from './checkServiceWorker';

const handleInitPushNotifications = async (registration) => {
	const { default: pushNotifications } = await import('./pushNotifications');
	return async (authHeader) => pushNotifications(registration, authHeader);
};

const initPushNotifications = async (authHeader) => {
	try {
		if ('serviceWorker' in navigator && 'PushManager' in window) {
			const serviceWorkerData = await checkServiceWorker(authHeader);
			if (!serviceWorkerData.subscription) {
				return handleInitPushNotifications(serviceWorkerData.registration);
			}
		}
		return null;
	} catch (error) {
		console.error('Error initializing push notifications:', error);
		return null;
	}
};

export default initPushNotifications;
