import React from 'react';
import { TableCell, TableRow } from '@mui/material';
import Spinner from './spinner/Spinner';
import InfoModal from './modals/InfoModal';
import WideRowsTable from './tables/WideRowsTable';
import UserAvatar from './user/UserAvatar';
import LinkedPortfolioTag from './LinkedPortfolioTag';
import { sendClickEvent } from '../../utils/eventsService';

export default function ListModal(props) {
	const {
		show,
		setShow,
		isLoading,
		title,
		headers,
		rows,
		eventLocation,
		handleRowClick = (userName) => {
			sendClickEvent({
				element: 'list-modal-row',
				eventLocation,
			});
			window.open(`/u/${userName}`, '_blank');
		},
		extraKeys = [],
	} = props;
	return isLoading ? (
		<Spinner isLoading={show && isLoading} />
	) : (
		<InfoModal
			dialogClassName="temp"
			isShow={show}
			onChange={() => setShow(false)}
			contentClassName="user-list-modal"
		>
			<div className="card">
				<div className="card-body">
					<div className="d-flex flex-column full-width justify-content-center align-items-start">
						<h4 className="m-0">{title} </h4>
					</div>
					<WideRowsTable
						className="mt-1"
						headers={headers}
						id="track-element profile-card-holdings"
					>
						{rows.map((item, key) => (
							<TableRow key={key}>
								<TableCell
									onClick={() => handleRowClick(item?.user_name)}
									style={{ cursor: 'pointer', Width: '40px' }}
								>
									<div className="d-flex align-items-center">
										{item.highlight ? (
											<div
												className="rounded-circle mr-2"
												style={{
													width: '10px',
													height: '10px',
													backgroundColor: '#EE3232',
													fontSize: '12px',
												}}
											/>
										) : (
											''
										)}
										<UserAvatar
											id="profile-avatar"
											size={30}
											userAvatarUrl={item?.avatar_urls?.avatar_url_s}
											userName={item?.user_name}
											eventLocation="leaderboard"
											className="mr-2"
										/>
										<div className="d-flex flex-column align-items-start text-strong">
											{item.user_name}
											<LinkedPortfolioTag rank={item?.badges?.rank} />
										</div>
									</div>
								</TableCell>
								{extraKeys.map((extraKey) => (
									<TableCell key={extraKey}>{item[extraKey]}</TableCell>
								))}
								<TableCell
									onClick={() => handleRowClick(item?.user_name)}
									style={{ cursor: 'pointer' }}
								>
									{item?.is_verified?.includes('portfolio') && (
										<div className="text-center">
											<small
												id="track-element view-portfolio-follow-list"
												className="text-primary m-0 p-0 text-underline"
											>
												View Portfolio
											</small>
										</div>
									)}
								</TableCell>
							</TableRow>
						))}
					</WideRowsTable>
				</div>
			</div>
		</InfoModal>
	);
}
