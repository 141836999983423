import React, { createContext, useContext, useMemo, useState } from 'react';

export const NotFoundPageContext = createContext();

/**
 * @function useNotFoundPage
 * @returns {{isNotFoundPageError: boolean; setIsNotFoundPageError: Function}}
 */

export function useNotFoundPage() {
	return useContext(NotFoundPageContext);
}

export const NotFoundPageProvider = (props) => {
	const { children } = props;
	const [isNotFoundPageError, setIsNotFoundPageError] = useState(false);
	const [isDeletedPage, setIsDeletedPage] = useState(false);
	const value = useMemo(
		() => ({
			isNotFoundPageError,
			setIsNotFoundPageError,
			isDeletedPage,
			setIsDeletedPage,
		}),
		[isNotFoundPageError, isDeletedPage]
	);
	return (
		<NotFoundPageContext.Provider value={value}>
			{children}
		</NotFoundPageContext.Provider>
	);
};
