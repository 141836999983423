const isAppleMobile = () => {
	if (
		navigator.userAgent.includes(['iPhone', 'iPad', 'iPod']) ||
		(navigator.userAgent.match(/Mac/) &&
			navigator.maxTouchPoints &&
			navigator.maxTouchPoints > 2)
		// && 'serviceWorker' in navigator
	)
		return true;
	return false;
};

const isAppleDesktop = () => {
	// check if it's a mac
	const userAgent = navigator.userAgent.toLowerCase();
	if (navigator.maxTouchPoints || !userAgent.match(/macintosh/)) return false;
	// check safari version >= 17
	const version = /version\/(\d{2})\./.exec(userAgent);
	if (!version || !version[1] || !(parseInt(version[1]) >= 17)) return false;
	// hacky way to detect Sonoma
	const audioCheck = Boolean(
		document.createElement('audio').canPlayType('audio/wav; codecs="1"')
	);
	const webGLCheck = Boolean(new OffscreenCanvas(1, 1).getContext('webgl'));

	return audioCheck && webGLCheck;
};

const isStandalone = () => {
	if (
		window.matchMedia('(display-mode: standalone)').matches ||
		('standalone' in navigator && navigator.standalone === true)
	)
		return true;
	return false;
};

const getInstalledRelatedApps = async () => {
	if ('getInstalledRelatedApps' in navigator)
		try {
			return await navigator.getInstalledRelatedApps();
		} catch (e) {
			console.log(e);
			//
		}

	return [];
};

const isRelatedAppsInstalled = async () => {
	const relatedApps = await getInstalledRelatedApps();
	return Boolean(relatedApps?.length);
};

export {
	isAppleMobile,
	isAppleDesktop,
	isStandalone,
	getInstalledRelatedApps,
	isRelatedAppsInstalled,
};
