import React, {
	createContext,
	useCallback,
	useContext,
	useMemo,
	useState,
} from 'react';
import FollowList from '../components/common/FollowList';

const FollowListContext = createContext();

/**
 *
 * @returns {{
 * setFollowListData: (userName: string, followList: 'followers' | 'following', highlightFollowers?: [string])
 * }}
 */

const useFollowList = () => useContext(FollowListContext);

function FollowListProvider(props) {
	const { children } = props;
	const [highlightFollowers, setHighlightFollowers] = useState();
	const [userName, setUserName] = useState('');
	const [followList, setFollowList] = useState();
	const handleClose = useCallback(() => {
		setFollowList();
	}, []);
	const setFollowListData = useCallback(
		(newUserName, newFollowList, newHighlightFollowers) => {
			setUserName(newUserName);
			setFollowList(newFollowList);
			setHighlightFollowers(newHighlightFollowers);
		},
		[]
	);
	const value = useMemo(() => ({ setFollowListData }), [setFollowListData]);
	return (
		<FollowListContext.Provider value={value}>
			{children}
			{followList ? (
				<FollowList
					show={followList}
					setShow={handleClose}
					listType={followList}
					currentUserName={userName}
					highlightFollowers={highlightFollowers}
				/>
			) : null}
		</FollowListContext.Provider>
	);
}

export { FollowListProvider, useFollowList };
