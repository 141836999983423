import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import InfoModal from './InfoModal';
import goldGiftVector from '../../../images/gold-gift-vector.svg';
import getNumberName from '../../../utils/getNumberName';

function ModalConetent({ achievements, handleClose, hideClaim }) {
	const ClaimButton = useCallback(
		() =>
			!hideClaim && (
				<div className="w-100 px-4 my-3">
					<Button
						variant="primary"
						className="w-100 py-1"
						onClick={() => {
							handleClose();
						}}
					>
						Claim
					</Button>
				</div>
			),
		[hideClaim, handleClose]
	);
	if (!achievements || achievements.length === 0) {
		return null;
	}

	if (achievements.length === 1) {
		const achievement = achievements[0];
		return (
			<div className="px-3 d-flex flex-column align-items-center">
				<p className="mx-4 text-black">You have unlocked a new achievement</p>

				<div
					key={achievement.name}
					className="w-100 p-1 mb-2 d-flex flex-column align-items-center"
					style={{
						borderRadius: '10px',
						border: '1px solid #FFB100',
						background: '#FFF4E1',
					}}
				>
					<div className="d-flex align-items-center">
						<img className="mr-1" src={goldGiftVector} alt="" />
						<p className="mb-0 text-gold">{achievement.exp} EXP</p>
					</div>
					<img
						className="my-2 rounded-circle"
						height={100}
						width={100}
						src={achievement.logo}
						alt=""
					/>
					<p className="mb-0 mx-2 text-black">{achievement.title}</p>
				</div>
				<ClaimButton />
			</div>
		);
	}
	return (
		<div className="d-flex flex-column align-items-center">
			<p className="mx-4 text-black">
				You have unlocked {getNumberName(achievements.length)} new achievements
			</p>
			<div
				className="overflow-auto no-scroll-bar px-1"
				style={{ height: '200px' }}
			>
				{achievements.map((achievement) => (
					<div
						key={achievement.name}
						className="p-1 mb-2 d-flex align-items-center"
						style={{
							borderRadius: '10px',
							border: '1px solid #FFB100',
							background: '#FFF4E1',
						}}
					>
						<div className="w-100 d-flex align-items-center justify-content-between">
							<div className="d-flex align-items-center">
								<img
									className="rounded-circle"
									height={32}
									width={32}
									src={achievement.logo}
									alt=""
								/>
								<p className="mb-0 mx-2 text-black">{achievement.title}</p>
							</div>
							<div className="d-flex align-items-center">
								<img className="mr-1" src={goldGiftVector} alt="" />
								<p className="mb-0 text-gold">{achievement.exp} EXP</p>
							</div>
						</div>
					</div>
				))}
			</div>
			<ClaimButton />
		</div>
	);
}

export default function AchievementsAcquired({
	achievements,
	onClose,
	isModal,
	hideClaim,
}) {
	const [isShow, setIsShow] = useState(false);
	const doneAchievements = useMemo(
		() =>
			achievements.filter(
				(achievement) => achievement.user_data?.status === 'done'
			),
		[achievements]
	);
	const handleClose = useCallback(
		(newIsShow) => {
			if (!newIsShow) {
				if (typeof onClose === 'function') {
					onClose();
				}
				setIsShow(false);
			}
		},
		[onClose]
	);
	useEffect(() => {
		if (!isShow && achievements.length > 0) {
			setIsShow(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [achievements]);
	const childrenRender = (
		<ModalConetent
			achievements={doneAchievements}
			handleClose={handleClose}
			hideClaim={hideClaim}
		/>
	);
	if (!achievements) {
		return null;
	}
	if (isModal) {
		return (
			<InfoModal
				isShow={isShow}
				onChange={handleClose}
				closeMethod="none"
				dialogClassName="modal-400px"
				noClose
			>
				{childrenRender}
			</InfoModal>
		);
	}
	return childrenRender;
}
