import React, { useCallback, useRef } from 'react';
import { Button, Card } from 'react-bootstrap';

const defaultStyle = {};

export default function MobileModal({
	isShow,
	onChange,
	onceView,
	contentClassName,
	style = defaultStyle,
	children,
}) {
	const containerRef = useRef();
	const handleCloseModal = useCallback(() => {
		if (onChange) {
			onChange(false);
		}
		if (onceView) {
			onceView();
		}
	}, [onChange, onceView]);
	const onContainerClick = useCallback(
		(event) => {
			if (event.target.className.includes('mobile-modal-container')) {
				handleCloseModal();
			}
		},
		[handleCloseModal]
	);
	return !isShow ? null : (
		<Card
			role="button"
			tabIndex={0}
			className="mobile-modal-container show"
			onClick={onContainerClick}
		>
			<Card.Body
				ref={containerRef}
				className={`mobile-modal ${
					contentClassName ? ` ${contentClassName}` : ''
				}`}
				style={style}
			>
				<Button
					className="p-0 d-block"
					variant="none"
					onClick={handleCloseModal}
				>
					<span className="close-button">x</span>
				</Button>
				{children}
			</Card.Body>
		</Card>
	);
}
