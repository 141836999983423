import React from 'react';
import './css/style.css';
import Index from './components/Index';
import { UseScreenProvider } from './context/UseScreenContext';
import ErrorBoundary from './components/ErrorBoundary';

function App() {
	return (
		<ErrorBoundary scopeLevel="fatal">
			<div className="App">
				<UseScreenProvider>
					<Index />
				</UseScreenProvider>
			</div>
		</ErrorBoundary>
	);
}

export default App;
