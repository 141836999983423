import axios from 'axios';
import { SERVICE_WORKER_PATH } from './assets';
import isDevelopment from '../utils/isDevelopment';

const checkServiceWorker = async (authHeader) => {
	try {
		const registration = await navigator.serviceWorker.getRegistration(
			SERVICE_WORKER_PATH
		);

		const subscription = registration
			? await registration.pushManager.getSubscription()
			: null;

		const result = {
			registration,
			subscription,
		};

		if (isDevelopment) {
			if (subscription) {
				console.log('Is S ', subscription);
				await axios.post(
					'/api/user/notifications/subscribe/delete',
					{
						subscription,
					},
					{ headers: authHeader }
				);

				await subscription.unsubscribe();

				result.subscription = null;
			}

			if (registration) {
				console.log('Is R ', registration);
				await registration.unregister();
				result.registration = null;
			}
		}

		return result;
	} catch (error) {
		console.error('Error checking service worker registration:', error);
		return { registration: null, subscription: null };
	}
};

export default checkServiceWorker;
