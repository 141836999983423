import React from 'react';
import LogoRender from './logos/LogoRender';

export default function SearchboxOption({ logo, name, dataNavOption }) {
	return (
		<strong
			// eslint-disable-next-line
			dataNavOption={dataNavOption}
			className="d-flex align-items-center"
			style={{ cursor: 'pointer' }}
		>
			<LogoRender className="mr-2" src={logo} size={20} />
			<span style={{ textAlign: 'left' }}>{name}</span>
		</strong>
	);
}
