import React, { createContext, useCallback, useContext, useMemo } from 'react';
import {
	PayPalScriptProvider,
	usePayPalScriptReducer,
} from '@paypal/react-paypal-js';
import isDevelopment from '../utils/isDevelopment';

const providerOptions = {
	'client-id':
		'AXRoCAm8a7PjKSVPd2yCTQjEPP8u0NEFjv7MiYl3I-thw9yrui0LXA0lIch5QsimvO9dj21xM_0-zIoM',
	components: 'buttons',
};

if (isDevelopment) {
	providerOptions['client-id'] =
		'AQ4MgstfRbF6VA4y0b9Fz_TonyRwG_yFjfMbuX_kOUlpNFkXQT9YXDdeLGN6fCsR_zKNZohFX7mwL9m3';
	providerOptions.sdkBaseURL = 'https://www.sandbox.paypal.com';
}

const PaypalContext = createContext();

/**
 *
 * @returns {{
 * prepareSubscription: Function;
 * prepareCapture: Function;
 * prepareAuthorize: Function;
 * setValue: (name: string, value: any) => void;
 * }}
 */
const usePaypal = () => useContext(PaypalContext);

const PaypalProviderContent = (props) => {
	const { children } = props;
	const [{ options, isResolved }, dispatch] = usePayPalScriptReducer();
	const updateOptions = useCallback(
		(newOptions) => {
			dispatch({
				type: 'resetOptions',
				value: {
					...options,
					...newOptions,
				},
			});
		},
		[options, dispatch]
	);
	const prepareSubscription = useCallback(() => {
		updateOptions({
			intent: 'subscription',
			vault: true,
		});
	}, [updateOptions]);
	const prepareCapture = useCallback(() => {
		updateOptions({
			intent: 'capture',
			vault: false,
		});
	}, [updateOptions]);
	const prepareAuthorize = useCallback(() => {
		updateOptions({
			intent: 'authorize',
			vault: false,
		});
	}, [updateOptions]);
	const value = useMemo(
		() => ({
			isResolved,
			prepareSubscription,
			prepareCapture,
			prepareAuthorize,
		}),
		[isResolved, prepareSubscription, prepareCapture, prepareAuthorize]
	);
	return (
		<PaypalContext.Provider value={value}>{children}</PaypalContext.Provider>
	);
};

const PaypalProvider = (props) => {
	const { children } = props;
	return (
		<PayPalScriptProvider options={providerOptions}>
			<PaypalProviderContent>{children}</PaypalProviderContent>
		</PayPalScriptProvider>
	);
};

export { usePaypal, PaypalProvider };
