const PWA_MODAL_EXECUTED_KEY = 'pwa-modal-executed';
const PWA_MODAL_EXECUTED_VALUE = 'true';

/**
 * @description set session storage to disable PWA modal in the same session
 */
const setPWAModalExecuted = () => {
	sessionStorage.setItem(PWA_MODAL_EXECUTED_KEY, PWA_MODAL_EXECUTED_VALUE);
};

/**
 * @description check session storage if PWA modal already executed in the same session
 */
const checkPWAModalExecuted = () =>
	sessionStorage.getItem(PWA_MODAL_EXECUTED_KEY) === PWA_MODAL_EXECUTED_VALUE;

export {
	PWA_MODAL_EXECUTED_KEY,
	PWA_MODAL_EXECUTED_VALUE,
	setPWAModalExecuted,
	checkPWAModalExecuted,
};
