// In case the client is unable to download one of the js chunks, try to load it again with no more than [attemtpsLeft] tries.
// It can happens if the network is throttled and unstable, or because filenames no longer exists when the user uses old version of the app.
// For more information: https://medium.com/@botfather/react-loading-chunk-failed-error-88d0bb75b406
const componentLoader = (lazyComponent, attemptsLeft = 5) =>
	new Promise((resolve, reject) => {
		lazyComponent()
			.then(resolve)
			.catch((error) => {
				setTimeout(() => {
					if (attemptsLeft === 1) {
						reject(error);
						return;
					}
					componentLoader(lazyComponent, attemptsLeft - 1).then(
						resolve,
						reject
					);
				});
			});
	});

export default componentLoader;
