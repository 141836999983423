import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useNotFoundPage } from '../../context/NotFoundPageContext';
import ListModal from './ListModal';

export default function FollowList(props) {
	const { show, setShow, listType, currentUserName, highlightFollowers } =
		props;
	const { setIsNotFoundPageError } = useNotFoundPage();
	const [isLoading, setIsLoading] = useState(false);
	const [members, setMembers] = useState([]);

	const headers = [{ label: 'User' }, { label: '' }];

	const navigate = useNavigate();

	const title = useMemo(
		() =>
			listType === 'followers'
				? `Followers of ${currentUserName}`
				: `Followed by ${currentUserName}`,
		[listType, currentUserName]
	);

	const handleRowClick = (userName) => {
		setShow(false);
		navigate(`/u/${userName}`);
	};

	useEffect(() => {
		if (members.length === 0 && show) {
			axios
				.get(`/api/stream/${listType}/${currentUserName}`)
				.then((res) => {
					setMembers(
						!highlightFollowers
							? res.data[listType]
							: res.data[listType].map((v) =>
									highlightFollowers.includes(v.user_name)
										? { ...v, highlight: true }
										: v
							  )
					);
				})
				.catch((error) => {
					if (error?.response?.status === 404) {
						setIsNotFoundPageError(true);
					}
				})
				.finally(() => setIsLoading(false));
		}
	}, [
		show,
		members,
		listType,
		currentUserName,
		highlightFollowers,
		setIsNotFoundPageError,
	]);

	return (
		<ListModal
			show={show}
			setShow={setShow}
			isLoading={isLoading}
			title={title}
			headers={headers}
			rows={members}
			handleRowClick={handleRowClick}
		/>
	);
}
