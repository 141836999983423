import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import {
	TableHead,
	TableRow,
	tableCellClasses,
	TableCell,
	styled,
	TableFooter,
} from '@mui/material/';
import Spinner from '../spinner/Spinner';

/**
 * @function WideRowsTable
 * @param {{
 * className?: string;
 * headers?: [JSX.Element] | [{label: string, onClick?: Function?}];
 * isSticky?: boolean;
 * isLoading?: boolean;
 * tableStyle?: object;
 * tableCellStyle?: object;
 * secondaryLabels?: boolean;
 * children: Node;
 * }} props
 * @returns
 */

export default function WideRowsTable(props) {
	const {
		className = '',
		headers = [],
		footer,
		isSticky,
		isLoading = false,
		tableStyle = {},
		tableCellStyle = {},
		secondaryLabels = false,
		children,
		headerStyle,
	} = props;
	const StyledHeadCell = styled(TableCell)(() => ({
		[`&.${tableCellClasses.head}`]: {
			// backgroundColor: '#1652F0',
			// color: '#1652F0',
			minWidth: 'initial',
			borderBottom: '1px solid #E0E0E0',
			// borderTop: '1px solid #E0E0E0',
			fontWeight: '600',
			fontFamily: 'Nunito',
			...tableCellStyle,
		},
	}));
	const firstCellStyle = {
		borderTopLeftRadius: '5px',
		borderBottomLeftRadius: '5px',
	};
	const lastCellStyle = {
		borderTopRightRadius: '5px',
		borderBottomRightRadius: '5px',
	};

	const calculateCellStyle = (index) => {
		if (index === 0) {
			return { ...firstCellStyle, ...headerStyle };
		}
		if (index === headers.length - 1) {
			return { ...lastCellStyle, ...headerStyle };
		}
		return { ...headerStyle };
	};
	return !isLoading ? (
		<TableContainer className={`fixed-table ${className}`} style={tableStyle}>
			<Table stickyHeader={isSticky}>
				{headers.length > 0 ? (
					<TableHead>
						{Array.isArray(headers[0]) ? (
							headers.map((header, index) => (
								<TableRow key={index}>
									{header.map((headerCell, key) => {
										const { label, onClick } = headerCell || {};
										return typeof label === 'string' ? (
											<StyledHeadCell
												className={
													isSticky && key === 0 ? 'sticky-col first-col' : ''
												}
												key={key}
												onClick={onClick}
												style={calculateCellStyle(key)}
											>
												{label}
											</StyledHeadCell>
										) : (
											headerCell
										);
									})}
								</TableRow>
							))
						) : (
							<TableRow>
								{headers.map((headerCell, key) => {
									const {
										label,
										secondaryLabel,
										headerClassName = '',
										onClick,
										sorted,
										sortable,
									} = headerCell || {
										label: '',
									};
									return typeof label === 'string' ? (
										<StyledHeadCell
											className={`${headerClassName} ${
												isSticky && key === 0 ? 'sticky-col first-col' : ''
											}`}
											key={key}
											onClick={onClick}
											style={{
												...calculateCellStyle(key),
												cursor: onClick ? 'pointer' : 'auto',
											}}
										>
											{label}
											{sortable ? (
												<i
													className={`ml-1 fa fa-caret-${
														sorted <= 0 ? 'down' : 'up'
													} ${!sorted ? ' text-gray' : ''}`}
												/>
											) : null}
											{secondaryLabel ? (
												<>
													<br />
													<span style={{ fontWeight: 500 }}>
														{secondaryLabel}
													</span>
												</>
											) : null}
											{secondaryLabels && !secondaryLabel ? (
												<>
													<br />
													<div style={{ width: '1px', height: '24px' }} />
												</>
											) : null}
										</StyledHeadCell>
									) : (
										headerCell
									);
								})}
							</TableRow>
						)}
					</TableHead>
				) : (
					''
				)}
				{children && <TableBody>{children}</TableBody>}
				{footer && <TableFooter>{footer}</TableFooter>}
			</Table>
		</TableContainer>
	) : (
		<Spinner isLoading className="infinite-loader" type="Oval" />
	);
}
