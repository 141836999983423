import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../context/Auth';

function InnerPrivateRoute({ authPrivilege = null, children }) {
	const { authTokens, getAuthHeader, setAuthModalType } = useAuth();
	const navigate = useNavigate();

	useEffect(() => {
		if (!authTokens) {
			navigate('/', { replace: true });
			setAuthModalType('signin');
		} else if (authPrivilege) {
			axios
				.get(`/api/check_privileges/${authPrivilege}`, {
					headers: getAuthHeader(),
				})
				.catch(() => {
					navigate('/', { replace: true });
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return children;
}

/**
 * @function PrivateRoute
 * @param {{
 * authPrivilege?: string?;
 * component: JSX.Element
 * }} props
 * @returns
 */

export default function PrivateRoute({ authPrivilege, children }) {
	return (
		<InnerPrivateRoute authPrivilege={authPrivilege}>
			{children}
		</InnerPrivateRoute>
	);
}
