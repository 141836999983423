import React, { createContext, useContext } from 'react';

export const Auth = createContext();

/**
 * @function useAuth
 * @returns {{
 *  authTokens: object?;
 * 	getAuthHeader: () => {Authorization: `Bearer ${token}` | null;};
 * }}
 * @see getAuthHeader
 */

export function useAuth() {
	return useContext(Auth);
}

export const AuthProvider = (props) => {
	const { value, children } = props;
	return <Auth.Provider value={value}>{children}</Auth.Provider>;
};

// value used for testing
export const AuthMockValue = {
	authTokens: [{ token: '' }],
	getAuthHeader: { Authorization: 'Bearer ' },
};
