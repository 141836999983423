import React, {
	createContext,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import axios from 'axios';
import { usePWA } from './PWAContext';
import initPushNotifications from '../PWA/initPushNotifications';
import { useAuth } from './Auth';
import EnableNotificationsModal from '../PWA/EnableNotificationsModal';
import { useAuthQueue } from './AuthQueue';

const PushNotificationsContext = createContext();

const usePushNotifications = () => useContext(PushNotificationsContext);

function PushNotificationsProvider({ children }) {
	const { isPWA } = usePWA();
	const { authTokens, getAuthHeader } = useAuth();
	const { appendAuthQueue } = useAuthQueue();

	const initPushNotificationsCall = useRef();

	const [isReady, setIsReady] = useState(false);
	const [isDone, setIsDone] = useState(false);

	useEffect(() => {
		if (!isReady && isPWA) {
			initPushNotifications(getAuthHeader()).then(
				(tempInitPushNotificationsCall) => {
					if (tempInitPushNotificationsCall) {
						if (authTokens) {
							initPushNotificationsCall.current = () =>
								tempInitPushNotificationsCall(getAuthHeader());
						} else {
							initPushNotificationsCall.current = () =>
								tempInitPushNotificationsCall().then((subscription) => {
									appendAuthQueue({
										name: 'push-notifications-subscriptions',
										call: (authHeader) =>
											axios
												.post(
													'/api/user/notifications/subscribe',
													{
														subscription,
													},
													{ headers: authHeader }
												)
												.catch((error) => {
													throw error;
												}),
									});
								});
						}
						setIsReady(true);
					}
				}
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isPWA, authTokens]);

	useEffect(() => {
		if (isDone) {
			initPushNotificationsCall.current = null;
		}
	}, [isDone]);

	const value = useMemo(
		() => ({
			ready:
				isReady && !isDone
					? {
							enableNotifications: (defferSetState) =>
								initPushNotificationsCall.current().then((res) => {
									if (defferSetState) {
										return () => {
											setIsDone(true);
											return res;
										};
									}
									setIsDone(true);
									return res;
								}),
					  }
					: false,
		}),
		[isReady, isDone]
	);

	return (
		<PushNotificationsContext.Provider value={value}>
			{isReady && !isDone && authTokens ? (
				<EnableNotificationsModal
					isShow
					enableNotifications={value.ready.enableNotifications}
				/>
			) : null}
			{children}
		</PushNotificationsContext.Provider>
	);
}

export { PushNotificationsProvider, usePushNotifications };
